<template>
  <v-footer
    class="py-1"
    absolute
    app
    inset
  >
    <v-row>
      <v-col class="text-center">
        @ {{ new Date().getFullYear() }} - Made with
        <v-icon
          size="18"
          color="red"
        >
          mdi-heart
        </v-icon>
        by Travikr Team.
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',

    data: () => ({
      links: [
        {
          href: '#',
          text: 'About Us',
        },
      ],
    }),
  }
</script>

<style scoped>

</style>
